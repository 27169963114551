import catalogStyles from '../sass/catalog.scss';
import customPriceSliderJS from './includes/custom_price_slider.js';
import manufacturer1IMG from '../img/mfg-1.png';
import manufacturer2IMG from '../img/mfg-2.png';
import manufacturer3IMG from '../img/mfg-3.png';
import manufacturer4IMG from '../img/mfg-4.png';
import manufacturer5IMG from '../img/mfg-5.png';
import manufacturer6IMG from '../img/mfg-6.png';
import manufacturer7IMG from '../img/mfg-7.png';
import manufacturer8IMG from '../img/mfg-8.png';
import manufacturer9IMG from '../img/mfg-9.png';
import manufacturer10IMG from '../img/mfg-10.png';
import manufacturer11IMG from '../img/mfg-11.png';
import manufacturer12IMG from '../img/mfg-12.png';
import manufacturer13IMG from '../img/mfg-13.png';
import manufacturer14IMG from '../img/mfg-14.png';
import manufacturer15IMG from '../img/mfg-15.png';
import manufacturer17IMG from '../img/mfg-17.png';
import manufacturer18IMG from '../img/mfg-18.png';
import manufacturer19IMG from '../img/mfg-19.png';
import item1IMG from '../img/item-1.png';
import item2IMG from '../img/item-2.png';
import item3IMG from '../img/item-3.png';
import item4IMG from '../img/item-4.png';
import item5IMG from '../img/item-5.png';
import item6IMG from '../img/item-6.png';
import item7IMG from '../img/item-7.png';
import item8IMG from '../img/item-8.png';
import item9IMG from '../img/item-9.png';
import item10IMG from '../img/item-10.png';
import item11IMG from '../img/item-11.png';
import item12IMG from '../img/item-12.png';
import item13IMG from '../img/item-13.png';
import noImage from '../img/no-image.png';
